import React from "react"
import tw from "twin.macro"
import { SectionHeading as HeadingTitle } from "./misc/Headings.jsx"

const Container = tw.div`relative`
const Content = tw.div`max-w-screen-xl mx-auto my-6 lg:my-6`
const HeadingInfoContainer = tw.div`flex flex-col items-center`
const NewsContainer = tw.div`h-128 overflow-y-auto border border-gray-300 rounded-lg p-4 w-full bg-white`
const Details = tw.div`p-4   border-b-2 border-solid border-primary-100 flex-1 flex flex-col items-center text-center lg:block lg:text-left`
const Title = tw.div`mt-4 leading-snug font-bold text-lg`
const News = tw.a`mt-2 text-sm text-secondary-100`

export default () => {
  const newsPosts = [
    {
      date: "2024/08/16",
      news: "通信量40％削減を実現　非同期型の「台帳型連合学習」を開発し、物体検出分野における連合学習技術の適用を目指す",
      url: "https://www.rosso-tokyo.co.jp/rossofederatedlearning/",
    },
    {
      date: "2024/03/04",
      news: "【特許出願】大規模中央サーバーや複雑なネットワークを必要としない台帳型連合学習の特許を出願しました。",
      url: "https://www.rosso-tokyo.co.jp/rossopatent/",
    },
    {
      date: "2023/04/11",
      news: "手ブレ画像でも大丈夫！従来のOCRでは読み取れなかった文字も認識する「超解像OCR」をRossoが独自開発。",
      url: "https://www.rosso-tokyo.co.jp/%e6%89%8b%e3%83%96%e3%83%ac%e7%94%bb%e5%83%8f%e3%81%a7%e3%82%82%e5%a4%a7%e4%b8%88%e5%a4%ab%ef%bc%81%e5%be%93%e6%9d%a5%e3%81%aeocr%e3%81%a7%e3%81%af%e8%aa%ad%e3%81%bf%e5%8f%96%e3%82%8c%e3%81%aa/",
    },
    {
      date: "2022/08/31",
      news: "気象×デジタルサイネージ「SPOT INFO NOTICE(R)」―地球の鼓動を、みる時代―において、 Rossoがシステム開発で技術支援",
      url: "https://www.rosso-tokyo.co.jp/%e6%b0%97%e8%b1%a1x%e3%83%87%e3%82%b8%e3%82%bf%e3%83%ab%e3%82%b5%e3%82%a4%e3%83%8d%e3%83%bc%e3%82%b8%e3%80%8cspot-info-noticer%e3%80%8d%e2%80%95%e5%9c%b0%e7%90%83%e3%81%ae%e9%bc%93%e5%8b%95/",
    },
    {
      date: "2022/02/28",
      news: "「サクッとAI」と「サクッとクラウド」の リリース1周年を記念して“AI・クラウド何でも無料相談会”を開催",
      url: "https://www.rosso-tokyo.co.jp/%e4%bc%81%e6%a5%ad%e3%81%aedx%e6%8e%a8%e9%80%b2%e3%82%92%e6%94%af%e6%8f%b4%e3%81%99%e3%82%8b%e3%80%8c%e3%82%b5%e3%82%af%e3%83%83%e3%81%a8ai%e3%80%8d%e3%81%a8%e3%80%8c%e3%82%b5%e3%82%af%e3%83%83/",
    },
    {
      date: "2022/01/20",
      news: "Rosso、手軽に導入可能なDXソリューション 「サクッとAI」「サクッとクラウド」の商標登録完了のお知らせ",
      url: "https://www.atpress.ne.jp/news/291797",
    },
    {
      date: "2021/03/09",
      news: "日本ディープラーニング協会（JDLA）の正会員に就任しました",
      url: "https://www.atpress.ne.jp/news/250260",
    },
  ]
  return (
    <Container>
      <Content>
        <HeadingInfoContainer>
          <HeadingTitle id="news">News</HeadingTitle>
        </HeadingInfoContainer>
        <NewsContainer>
          {newsPosts.map((post, index) => (
            <Details key={index}>
              <Title>{post.date}</Title>
              <News href={post.url} target="_blank" rel="noopener noreferrer">
                {post.news}
              </News>
            </Details>
          ))}
        </NewsContainer>
      </Content>
    </Container>
  )
}